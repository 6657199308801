import React, { Suspense } from 'react'
import { createBrowserRouter } from 'react-router-dom'
import { ROUTE_PATH } from './route.constant'

import AppLayout from '../layouts/AppLayout'
import AuthLayout from '../layouts/AuthLayout'
import MainLayout from '../layouts/MainLayout'
import PageNotFound from '../pages/PageNotFound/PageNotFound'
import Authentication from '../pages/Authentication'
const ChangePassword = React.lazy(() =>
  import('../pages/Authentication/ChangePassword'),
)
const ResetPasswordSuccess = React.lazy(() =>
  import('../pages/Authentication/ResetPasswordSuccess'),
)

const CollectionManagement = React.lazy(() =>
  import('../pages/CollectionManagement'),
)
const ListMember = React.lazy(() => import('../pages/ListMember'))
const GroupConfirmation = React.lazy(() => import('../pages/GroupConfirmation'))
const CompensationInquiry = React.lazy(() =>
  import('../pages/InquiriesManagement/CompensationInquiry'),
)
const GroupRegistration = React.lazy(() =>
  import('../pages/InquiriesManagement/GroupRegistration'),
)
const ProtectionInquires = React.lazy(() =>
  import('../pages/InquiriesManagement/PilateAmuletInquiry'),
)
const CompensationCase = React.lazy(() => import('../pages/CompensationCase'))
const NewMemberPerMonth = React.lazy(() => import('../pages/NewMemberPerMonth'))
const MemberByGroup = React.lazy(() => import('../pages/MemberByGroup'))
const CompensationNews = React.lazy(() => import('../pages/CompensationNews'))
const ActivityHistory = React.lazy(() => import('../pages/ActivityHistory'))
const PilatesInsuranceCase = React.lazy(() =>
  import('../pages/PilatesInsuranceCase'),
)

export const router = createBrowserRouter([
  {
    path: '/',
    element: <AppLayout />,
    errorElement: <PageNotFound />,
    children: [
      {
        element: <AuthLayout />,
        children: [
          {
            path: ROUTE_PATH.LOGIN,
            element: (
              <Suspense fallback={null}>
                <Authentication />
              </Suspense>
            ),
          },
          {
            path: ROUTE_PATH.CHANGE_PASSWORD,
            element: (
              <Suspense fallback={null}>
                <ChangePassword />
              </Suspense>
            ),
          },
          {
            path: ROUTE_PATH.RESET_PASSWORD,
            element: (
              <Suspense fallback={null}>
                <ResetPasswordSuccess />
              </Suspense>
            ),
          },
        ],
      },
      {
        element: <MainLayout />,
        children: [
          {
            path: ROUTE_PATH.MEMBER_MANAGEMENT,
            element: (
              <Suspense fallback={null}>
                <ListMember />
              </Suspense>
            ),
          },
          {
            path: ROUTE_PATH.GROUP_COMFIRMATION,
            element: (
              <Suspense fallback={null}>
                <GroupConfirmation />
              </Suspense>
            ),
          },
          {
            path: ROUTE_PATH.COMPENSATION_INQUIRY,
            element: (
              <Suspense fallback={null}>
                <CompensationInquiry />
              </Suspense>
            ),
          },
          {
            path: ROUTE_PATH.GROUP_REGISTRATION,
            element: (
              <Suspense fallback={null}>
                <GroupRegistration />
              </Suspense>
            ),
          },
          {
            path: ROUTE_PATH.AMULET_INQUIRY,
            element: (
              <Suspense fallback={null}>
                <ProtectionInquires />
              </Suspense>
            ),
          },
          {
            path: ROUTE_PATH.COMPENSATION_CASE,
            element: (
              <Suspense fallback={null}>
                <CompensationCase />
              </Suspense>
            ),
          },
          {
            path: ROUTE_PATH.COMPENSATION_NEWS,
            element: (
              <Suspense fallback={null}>
                <CompensationNews />
              </Suspense>
            ),
          },
          {
            path: ROUTE_PATH.PILATES_INSURANCE_CASE,
            element: (
              <Suspense fallback={null}>
                <PilatesInsuranceCase />
              </Suspense>
            ),
          },
          {
            path: ROUTE_PATH.COLLECTION_MANAGEMENT,
            element: (
              <Suspense fallback={null}>
                <CollectionManagement />
              </Suspense>
            ),
          },
          {
            path: ROUTE_PATH.NEW_MEMBER_PER_MONTH,
            element: (
              <Suspense fallback={null}>
                <NewMemberPerMonth />
              </Suspense>
            ),
          },
          {
            path: ROUTE_PATH.NUMBER_OF_MEMBER_BY_GROUP,
            element: (
              <Suspense fallback={null}>
                <MemberByGroup />
              </Suspense>
            ),
          },
          {
            path: ROUTE_PATH.ACTIVITY_HISTORY,
            element: (
              <Suspense fallback={null}>
                <ActivityHistory />
              </Suspense>
            ),
          },
        ],
      },
    ],
  },
])
