import axios from 'axios'
import { getAccessToken } from '../constants/utils'
import { toast } from 'sonner'

const http = axios.create({
  baseURL: process.env.REACT_APP_API,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 30000,
})

http.interceptors.request.use(
  async config => {
    const token = getAccessToken() ?? ''
    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      }
    }
    return config
  },
  error => Promise.reject(error),
)

http.interceptors.response.use(
  response => {
    // Xử lý và định dạng dữ liệu nhận được từ API ở đây
    if (response.data) {
      // return response để chuyển tiếp dữ liệu đã được xử lý
      return response.data
    }
  },
  error => {
    // Xử lý lỗi response từ API ở đây
    // Throwing an error for components to handle it
    return toast.error(error?.response?.data?.message)
  },
)

export default http
