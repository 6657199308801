import { useLocation, useNavigate } from 'react-router-dom'
import { NavbarWrapper } from './styled'
import { ROUTE_PATH } from '../../routes/route.constant'
import { useState } from 'react'
import ModalChangePass from '../../pages/ModalChangePass/ModalChangePass'

const Navbar = () => {
  const navigate = useNavigate()
  const [isOpenModal, setIsOpenModal] = useState(false)
  const location = useLocation()
  const titlePage = () => {
    switch (location.pathname) {
      case '/':
      case ROUTE_PATH.MEMBER_MANAGEMENT:
        return <div className="text-header">会員情報</div>
      case ROUTE_PATH.GROUP:
        return <div className="text-header">団体確認⼀覧</div>
      case ROUTE_PATH.GROUP_COMFIRMATION:
        return <div className="text-header">団体一覧</div>
      case ROUTE_PATH.COMPENSATION_NEWS:
        return (
          <div className="text-header">
            <div>記事管理</div>
            <div className="sub-title">News</div>
          </div>
        )
      case ROUTE_PATH.COMPENSATION_CASE:
        return (
          <div className="text-header">
            <div>記事管理</div>
            <div className="sub-title">保証事例</div>
          </div>
        )
      case ROUTE_PATH.PILATES_INSURANCE_CASE:
        return (
          <div className="text-header">
            <div>記事管理</div>
            <div className="sub-title">ピラティスのお守り事例</div>
          </div>
        )
      case ROUTE_PATH.COMPENSATION:
        return <div className="text-header">補償問い合わせ⼀覧</div>
      case ROUTE_PATH.AMULET_INQUIRY:
        return (
          <div className="text-header">
            <div>問い合わせ</div>
            <div className="sub-title">ピラティスのおまもり</div>
          </div>
        )
      case ROUTE_PATH.USEFUL_ARTICLE:
        return <div className="text-header">記事管理</div>
      case ROUTE_PATH.ANALYSIS_MEMBER:
        return <div className="text-header">会員分析確認</div>
      case ROUTE_PATH.COLLECTION:
        return <div className="text-header">回収管理</div>
      case ROUTE_PATH.ANNUAL_SALES:
        return <div className="text-header">年度別売上管理</div>
      case ROUTE_PATH.COMPENSATION_INQUIRY:
        return (
          <div className="text-header">
            <div>問い合わせ</div>
            <div className="sub-title">補償関連</div>
          </div>
        )
      case ROUTE_PATH.NEW_MEMBER_PER_MONTH:
        return (
          <div className="text-header">
            <div>会員分析</div>
            <div className="sub-title">月別新規加入件数</div>
          </div>
        )
      case ROUTE_PATH.NUMBER_OF_MEMBER_BY_GROUP:
        return (
          <div className="text-header">
            <div>会員分析</div>
            <div className="sub-title">団体別会員数</div>
          </div>
        )
      case ROUTE_PATH.GROUP_REGISTRATION:
        return (
          <div className="text-header">
            <div>問い合わせ</div>
            <div className="sub-title">団体登録申請</div>
          </div>
        )
      case ROUTE_PATH.COLLECTION_MANAGEMENT:
        return <div className="text-header"> 回収管理</div>
      case ROUTE_PATH.ACTIVITY_HISTORY:
        return <div className="text-header"> 活動履歴</div>
      default:
        break
    }
  }
  const handleChangPass = () => {
    setIsOpenModal(true)
  }
  return (
    <NavbarWrapper>
      <div className="admin">
        <div style={{ cursor: 'pointer' }}>
          <img
            onClick={handleChangPass}
            src={process.env.PUBLIC_URL + '/images/admin.png'}
            width={'40px'}
            alt=""
          />
        </div>
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            localStorage.clear()
            navigate(ROUTE_PATH.LOGIN)
          }}
        >
          <img
            src={process.env.PUBLIC_URL + '/images/logout.png'}
            width={'24px'}
            alt=""
          />
        </div>
      </div>
      {titlePage()}
      {isOpenModal && (
        <ModalChangePass
          isOpen={isOpenModal}
          onCancel={() => {
            setIsOpenModal(false)
          }}
          onOk={() => {
            setIsOpenModal(false)
          }}
        />
      )}
    </NavbarWrapper>
  )
}

export default Navbar
