import { Col, Form, Input, Row } from 'antd'
import Button from '../../components/Button'
import ModalCommon from '../../components/ModalCommon'
import { isValidPassword } from '../../constants/utils'
import ModalConfirmChangePass from './ModalConfirmChangePass'
import { useState } from 'react'
// import ModalChangeSuccess from './ModalChangeSuccess'

const ModalChangePass = ({ isOpen, onCancel, onOk }) => {
  const [form] = Form.useForm()
  const getInfoAdmin = JSON.parse(localStorage.getItem('accessToken'))
  const nameAdmin = getInfoAdmin.user.name
  const emailAdmin = getInfoAdmin.user.email
  const [isOpenModalConfirm, setIsOpenModalConfirm] = useState(false)
  const [oldPassword, setoldPassword] = useState()
  const [newPassword, setnewPassword] = useState()

  const handleSubmit = async () => {
    try {
      //   setLoading(true)
      const values = await form.validateFields()
      const oldPasswordValue = values.oldPassword
      const newPasswordValue = values.confirmPass
      setoldPassword(oldPasswordValue)
      setnewPassword(newPasswordValue)
      setIsOpenModalConfirm(true)
    } catch (error) {}
  }
  return (
    <ModalCommon
      title="管理者の情報"
      open={isOpen}
      width="740px"
      onOk={onOk}
      onCancel={onCancel}
      footer={
        <div
          style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}
        >
          {/* <Button onClick={onCancel}>キャンセル</Button> */}
          <Button
            onClick={handleSubmit}
            bg_color="#5BD4C1"
            color="white"
            style={{ width: '120px' }}
          >
            決定
          </Button>
        </div>
      }
    >
      {/* <Spin spinning={loading}> */}
      <Form
        form={form}
        labelAlign="left"
        labelWrap
        // wrapperCol={{
        //   flex: 1,
        // }}
        colon={false}
        autoComplete="off"
        initialValues={{
          fullname: nameAdmin,
          email: emailAdmin,
        }}
      >
        <Row>
          <Col span={24}>
            <Form.Item labelCol={{ span: 7 }} label="名前" name={'fullname'}>
              <Input readOnly disabled={true} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              labelCol={{ span: 7 }}
              label="メールアドレス"
              name={'email'}
            >
              <Input readOnly disabled={true} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              labelCol={{ span: 7 }}
              label="以前のパスワード"
              name={'oldPassword'}
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: '以前のパスワードフィールドは必須です',
                },
                {
                  pattern: isValidPassword(),
                  message:
                    'パスワードは少なくとも 6 文字以上にする必要があります。',
                },
              ]}
            >
              <Input.Password placeholder="以前のパスワード" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              labelCol={{ span: 7 }}
              label="新しいパスワード"
              name={'password'}
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: '新しいパスワードフィールドは必須です',
                },
                {
                  pattern: isValidPassword(),
                  message:
                    'パスワードは少なくとも 6 文字以上にする必要があります。',
                },
              ]}
            >
              <Input.Password placeholder="新しいパスワード" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              labelCol={{ span: 7 }}
              label="新パスワード（確認)"
              name={'confirmPass'}
              rules={[
                {
                  required: true,
                  message: '新しいパスワードフィールドは必須です',
                },
                {
                  pattern: isValidPassword(),
                  message:
                    'パスワードは少なくとも 6 文字以上にする必要があります。',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const newPassword = getFieldValue('password')

                    if (!value || newPassword === value || value.length < 6) {
                      return Promise.resolve()
                    }

                    return Promise.reject(
                      new Error(
                        '入力された新パスワードと新パスワード（確認）が一致する必要があります。',
                      ),
                    )
                  },
                }),
              ]}
              validateTrigger={['onChange', 'onBlur']}
            >
              <Input.Password placeholder="新しいパスワード（確認用） " />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {/* </Spin> */}
      {isOpenModalConfirm && (
        <ModalConfirmChangePass
          oldPassword={oldPassword}
          newPassword={newPassword}
          isOpen={isOpenModalConfirm}
          onCancel={() => {
            setIsOpenModalConfirm(false)
          }}
          onConfirm={() => {
            setIsOpenModalConfirm(false)
            onOk()
          }}
        />
      )}
    </ModalCommon>
  )
}

export default ModalChangePass
