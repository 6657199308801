/* eslint-disable no-useless-escape */
import { ACCESS_TOKEN_KEY } from './init.constants'

export const getAuthLocalStorage = () => {
  try {
    const authData = localStorage.getItem(ACCESS_TOKEN_KEY)
    return authData && JSON.parse(authData)
  } catch (error) {}
}

export const formatMoney = money => {
  return money.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const getAccessToken = () => {
  const data = getAuthLocalStorage()
  return data?.accessToken || ''
}

export const isValidPassword = () => {
  const regex = /^\S{6,}$/
  return regex
}

export const isValidEmail = () => {
  // const regex = /^(?![@.])[\w.-]+@[a-zA-Z0-9]+\.[a-zA-Z]{2,}$/
  const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
  return regex
}

export const isValidPostalCode = () => {
  const regex = /^(?=.*\d).{7}$/
  return regex
}

export const isRegexSpecialCharacter = () => {
  const regex = /^[^-.!@#$%^&*()_+=]*$/
  return regex
}
export const isRegexSpecialCharacterJP = () => {
  const regex =
    /^(.*[a-zA-Z0-9\u3040-\u30FF\u3400-\u4DBF\u4E00-\u9FFF\uF900-\uFAFF\uFF66-\uFF9D].*)+$/
  return regex
}
export const isRegexFurigana = () => {
  const regex = /^[^a-zA-Z]*$/
  return regex
}

export const isValidPhoneNumber = () => {
  const regex = /^(?=.*\d).{10,11}$/
  return regex
}

export const customLabelChart = {
  id: 'customLabelChart',
  afterDatasetsDraw(chart) {
    const {
      ctx,
      chartArea: { top },
    } = chart
    ctx.save()
    ctx.font = '14px Arial'
    ctx.fillText('件', 27, top - 20)
    ctx.restore()
  },
}
