export const ROUTE_PATH = {
  MEMBER_MANAGEMENT: '/member-management',
  LOGIN: '/login',
  CHANGE_PASSWORD: '/reset-password',
  RESET_PASSWORD: '/reset-password-success',
  GROUP_COMFIRMATION: '/group-confirmation',
  COMPENSATION_INQUIRY: '/compensation-inquiry-list',
  GROUP_REGISTRATION: '/group-registration',
  COMPENSATION_CASE: '/compensation-case',
  COMPENSATION_NEWS: '/compensation-news',
  PILATES_INSURANCE_CASE: '/pilates-insurance-case',
  AMULET_INQUIRY: '/pilate-amulet-inquires',
  NEW_MEMBER_PER_MONTH: '/new-member-per-month',
  NUMBER_OF_MEMBER_BY_GROUP: '/nember-of-member-by-group',
  COLLECTION_MANAGEMENT: '/collection-management',
  ANNUAL_SALES: '/annual-sales',
  ACTIVITY_HISTORY: '/activity-history',
}
