import { Menu } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { SidebarWrapper } from './styled'
import { ROUTE_PATH } from '../../routes/route.constant'

const rootSubmenuKeys = [
  {
    tabKey: 'sub1',
    tabPath: ROUTE_PATH.MEMBER_MANAGEMENT,
  },
  {
    tabKey: 'sub2',
    tabPath: ROUTE_PATH.GROUP_COMFIRMATION,
  },
  {
    tabKey: 'sub3_0',
    tabPath: ROUTE_PATH.COMPENSATION_INQUIRY,
  },
  {
    tabKey: 'sub3_1',
    tabPath: ROUTE_PATH.GROUP_REGISTRATION,
  },

  {
    tabKey: 'sub3_2',
    tabPath: ROUTE_PATH.AMULET_INQUIRY,
  },
  {
    tabKey: 'sub5_0',
    tabPath: ROUTE_PATH.COMPENSATION_NEWS,
  },
  {
    tabKey: 'sub5_1',
    tabPath: ROUTE_PATH.COMPENSATION_CASE,
  },
  {
    tabKey: 'sub5_2',
    tabPath: ROUTE_PATH.PILATES_INSURANCE_CASE,
  },
  {
    tabKey: 'sub6_0',
    tabPath: ROUTE_PATH.NEW_MEMBER_PER_MONTH,
  },
  {
    tabKey: 'sub6_1',
    tabPath: ROUTE_PATH.NUMBER_OF_MEMBER_BY_GROUP,
  },
  {
    tabKey: 'sub7',
    tabPath: ROUTE_PATH.COLLECTION_MANAGEMENT,
  },
  {
    tabKey: 'sub8',
    tabPath: ROUTE_PATH.ANNUAL_SALES,
  },
  {
    tabKey: 'sub9',
    tabPath: ROUTE_PATH.ACTIVITY_HISTORY,
  },
]

const Sidebar = () => {
  const location = useLocation()
  const [keySelected, setKeySelected] = useState('')
  const [openKeys, setOpenKeys] = useState([])
  const getItem = (label, key, icon, children, type) => {
    return {
      key,
      icon,
      children,
      label,
      type,
    }
  }

  const items = [
    getItem(<Link to={ROUTE_PATH.MEMBER_MANAGEMENT}>会員情報</Link>, 'sub1'),
    getItem(<Link to={ROUTE_PATH.GROUP_COMFIRMATION}>団体一覧</Link>, 'sub2'),
    getItem(
      <Link
        // onClick={() => setOpenKeys(['sub3'])}
        to="#"
        className={
          [ROUTE_PATH.COMPENSATION_INQUIRY].indexOf(location.pathname) !== -1
            ? 'active-link'
            : 'inactive-link'
        }
      >
        問い合わせ
      </Link>,
      'sub3',
      null,
      [
        getItem(
          <Link to={ROUTE_PATH.COMPENSATION_INQUIRY}>補償関連</Link>,
          'sub3_0',
        ),
        getItem(
          <Link to={ROUTE_PATH.GROUP_REGISTRATION}>団体登録申請</Link>,
          'sub3_1',
        ),
        getItem(
          <Link to={ROUTE_PATH.AMULET_INQUIRY}>ピラティスのおまもり</Link>,
          'sub3_2',
        ),
      ],
    ),
    getItem(
      <Link
        // onClick={() => setOpenKeys(['sub5'])}
        to="#"
        className={
          [ROUTE_PATH.COMPENSATION_NEWS, ROUTE_PATH.COMPENSATION_CASE].indexOf(
            location.pathname,
          ) !== -1
            ? 'active-link'
            : 'inactive-link'
        }
      >
        記事管理
      </Link>,
      'sub5',
      null,
      [
        getItem(
          <Link to={ROUTE_PATH.COMPENSATION_NEWS}>ニュース</Link>,
          'sub5_0',
        ),
        getItem(
          <Link
            to={ROUTE_PATH.COMPENSATION_CASE}
            // className={
            //   [ROUTE_PATH.COMPENSATION_CASE].indexOf(location.pathname) !== -1
            //     ? 'active-link'
            //     : 'inactive-link'
            // }
          >
            補償事例
          </Link>,
          'sub5_1',
        ),
        getItem(
          <Link to={ROUTE_PATH.PILATES_INSURANCE_CASE}>
            ピラティスのお守り事例
          </Link>,
          'sub5_2',
        ),
      ],
    ),
    getItem(<Link to="#">会員分析</Link>, 'sub6', null, [
      getItem(
        <Link to={ROUTE_PATH.NEW_MEMBER_PER_MONTH}>月別新規加入件数</Link>,
        'sub6_0',
      ),
      getItem(
        <Link to={ROUTE_PATH.NUMBER_OF_MEMBER_BY_GROUP}>団体別会員数</Link>,
        'sub6_1',
      ),
    ]),
    getItem(
      <Link to={ROUTE_PATH.COLLECTION_MANAGEMENT}>回収管理</Link>,
      'sub7',
    ),
    // getItem(<Link to={ROUTE_PATH.ANNUAL_SALES}>年度別売上管理</Link>, 'sub8'),
    getItem(<Link to={ROUTE_PATH.ACTIVITY_HISTORY}>活動履歴</Link>, 'sub9'),

    // 学習分析
  ]

  const updateOpenKeys = () => {
    switch (location.pathname) {
      case ROUTE_PATH.GROUP_REGISTRATION:
        setOpenKeys(['sub3'])
        break
      case ROUTE_PATH.COMPENSATION_INQUIRY:
        setOpenKeys(['sub3'])
        break
      case ROUTE_PATH.AMULET_INQUIRY:
        setOpenKeys(['sub3'])
        break
      case ROUTE_PATH.COMPENSATION_NEWS:
        setOpenKeys(['sub5'])
        break
      case ROUTE_PATH.COMPENSATION_CASE:
        setOpenKeys(['sub5'])
        break
      case ROUTE_PATH.PILATES_INSURANCE_CASE:
        setOpenKeys(['sub5'])
        break
      case ROUTE_PATH.NEW_MEMBER_PER_MONTH:
        setOpenKeys(['sub6'])
        break
      case ROUTE_PATH.NUMBER_OF_MEMBER_BY_GROUP:
        setOpenKeys(['sub6'])
        break
      default:
        setOpenKeys([])
        break
    }
  }

  useEffect(() => {
    const key = rootSubmenuKeys.find(
      item => item.tabPath === location.pathname,
    ).tabKey
    setKeySelected(key)

    updateOpenKeys()
  }, [location.pathname])

  const handleOpenChange = keys => {
    setOpenKeys(keys)
  }
  return (
    <SidebarWrapper>
      <div style={{ padding: '32px 40px 20px 24px' }}>
        <img
          src={process.env.PUBLIC_URL + '/images/pilates_logo.png'}
          width={'100%'}
          alt=""
        />
      </div>
      <Menu
        mode="inline"
        selectedKeys={[keySelected]}
        openKeys={openKeys}
        items={items}
        onOpenChange={handleOpenChange}
        onClick={e => {
          setKeySelected(e.key)
        }}
      />
    </SidebarWrapper>
  )
}

export default Sidebar
