import { Empty } from 'antd'

export const ACCESS_TOKEN_KEY = 'accessToken'
export const ADMIN_INFO = 'Admin_Info'
export const DEFAULT_PAGE_SIZE = 10
export const PAGE_SIZE_OPTIONS = [10, 20, 50, 100]
export const formatDateDefault = 'DD/MM/YYYY'
export const formatDateDisplay = 'YYYY/MM/DD'
export const converStatus = status => {
  switch (status) {
    case 'NO_REPLY':
      return '未返信'
    case 'REPLYED':
      return '返信済み'
    case 'TRANSFERRED':
      return '転送済み'
    case 'PAID':
      return '回収済'
    case 'WAIT_CONFIRM':
      return '未返信'
    case 'ACTIVE':
      return '会員'
    case 'INACTIVE':
      return '未更新'
    case 'WAIT_PAYMENT':
      return '未回収'
    case 'NOT_UPDATED':
      return '未更新'
    case 'NOT_PAYMENT':
      return '未更新'
    default:
      return '返信済み'
  }
}
export const converStatusPayment = status => {
  switch (status) {
    case 'PAID':
      return '回収済'
    case 'WAIT_CONFIRM':
      return '振込手続中'
    default:
      return '未回収'
  }
}
export const converChangeHistory = change => {
  switch (change) {
    case 'gender':
      return '性別'
    case 'name':
      return '名前'
    case 'address':
      return '住所'
    case 'password':
      return 'パスワード'
    case 'email':
      return 'メールアドレス'
    case 'furigana':
      return 'ふりがな'
    case 'postalCode':
      return '郵便番号'
    case 'membershipType':
      return '会員種別'
    case 'typeMember':
      return '会員タイプ'
    case 'dateOfBirth':
      return '生年月日'
    case 'phone':
      return '電話番号'
    default:
      return '補償期間'
  }
}
export const converMemberShipType = membershipType => {
  switch (membershipType) {
    case 'GOLD':
      return 'ゴールド'
    case 'SILVER':
      return 'シルバー'
    default:
      return 'ブロンズ'
  }
}
export const converType = Type => {
  switch (Type) {
    case 'REGISTER':
      return '新規加入'
    case 'RENEWAL':
      return '更新'
    default:
      return 'アップグレード'
  }
}
export const converMethod = Method => {
  switch (Method) {
    case 'ATM':
      return 'ATM・窓口'
    case 'CREDIT_CARD':
      return 'クレジットカード'
    default:
      return 'ネットバンキング'
  }
}
export const CustomEmptyDescription = () => (
  <Empty
    image={Empty.PRESENTED_IMAGE_SIMPLE}
    imageStyle={{ margin: '20px' }}
    description={
      <span style={{ color: 'rgba(0, 0, 0, 0.45)' }}>データがありません</span>
    }
  />
)
