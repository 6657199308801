import Button from '../../components/Button'
import ModalCommon from '../../components/ModalCommon'

const ModalChangeSuccess = ({ isOpen, onCancel, onOk }) => {
  const handleSubmit = () => {
    onOk()
  }
  return (
    <ModalCommon
      title="パスワード変更"
      open={isOpen}
      width="740px"
      onOk={onOk}
      onCancel={onCancel}
      footer={
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            borderTop: '1px solid #E9E9E9',
            gap: '16px',
          }}
        >
          <Button
            onClick={handleSubmit}
            bg_color="#5BD4C1"
            color="white"
            style={{ marginTop: '16px', width: '120px' }}
          >
            決定
          </Button>
        </div>
      }
    >
      <div
        style={{
          color: '#000',
          margin: '142px',
          display: 'flex',
          fontSize: '15px',
          justifyContent: 'center',
        }}
      >
        パスワードが無事に変更されました。
      </div>
    </ModalCommon>
  )
}

export default ModalChangeSuccess
