import { Layout, theme } from 'antd'
import { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { ACCESS_TOKEN_KEY } from '../../constants/init.constants'
import { ROUTE_PATH } from '../../routes/route.constant'
import './layout.scss'
import Sidebar from './Sidebar'
import Navbar from './Navbar'
const { Content, Sider } = Layout

const MainLayout = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken()
  const navigate = useNavigate()
  const isLogin = localStorage.getItem(ACCESS_TOKEN_KEY)

  useEffect(() => {
    if (!isLogin) return navigate(ROUTE_PATH.LOGIN)
  }, [])

  return (
    // <Layout>
    <Layout>
      <Sider
        width={250}
        style={{
          background: colorBgContainer,
          borderRight: '1px solid rgba(0, 0, 0, 0.10)',
        }}
      >
        <Sidebar />
      </Sider>
      <Layout>
        <Navbar />

        <Content style={{ padding: '20px' }}>
          <div className="box-container">
            <Outlet />
          </div>
        </Content>
      </Layout>
    </Layout>
    // </Layout>
  )
}

export default MainLayout
