import http from '.'
import {
  apiForgotPassword,
  apiGetInfoUser,
  apiLogin,
  apiUpdatePassword,
} from './apiRouter'

const login = body => http.post(apiLogin, body)

const getInfoMember = body => {
  return http.get(`${apiGetInfoUser}/${body}`)
}
const updatePassword = (params, bodyData) => {
  return http.patch(`${apiUpdatePassword}`, bodyData)
}
const sentEmailForgotPassword = (_, bodyData) => {
  return http.post(`${apiForgotPassword}`, bodyData)
}
const ResetPassword = (params, bodyData) => {
  return http.patch(`${apiForgotPassword}`, bodyData)
}

const AuthServices = {
  login,
  getInfoMember,
  updatePassword,
  sentEmailForgotPassword,
  ResetPassword,
}

export default AuthServices
