import { Suspense, useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from '../../routes/route.constant'

const AppLayout = () => {
  // const isLogin = getAccessToken()
  const navigate = useNavigate()
  const location = useLocation()
  // const authData = localStorage.getItem(KEY_ADMIN)

  useEffect(() => {
    if (location.pathname === '/') navigate(ROUTE_PATH.MEMBER_MANAGEMENT)
  })

  return (
    <Suspense fallback={null}>
      <Outlet />
    </Suspense>
  )
}

export default AppLayout
