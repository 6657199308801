// Auth
export const apiLogin = 'auth/sign-in'
export const apiGetInfoUser = 'auth/users'
export const apiUpdatePassword = 'auth/update-password'
export const apiForgotPassword = 'auth/forgot-password'

// User
export const apiGetUser = 'users'
export const apiExportUser = 'users/export-csv'
export const apiImportUser = 'users/import-csv'

//Organization
export const apiGetOrg = 'organizations'
export const apiGetOrgConfirm = 'organizations/users'

//InsurancePackage
export const apiGetPackage = 'insurance-package'

// News
export const apiGetNews = 'news'

// File
export const apiUploadFile = 'uploads/file'
//CompenSationInquiryService
export const apiGetInquiry = 'reports'
//PilateAmuletInquiry
export const apiGetAmuletInquiry = 'pilates-amulet'
//Payments
export const apiPayments = 'payments'
//Activity History
export const apiHistory = 'activity-history'

// Membership Analysis
export const apiNewMemberPerMonth = 'membership-analysis/per-month'
export const apiMemberByGroup = 'membership-analysis/by-organization'
