// import { useState } from 'react'
import { useState } from 'react'
import Button from '../../components/Button'
import ModalCommon from '../../components/ModalCommon'
import ModalChangeSuccess from './ModalChangeSuccess'
// import ModalChangeSuccess from './ModalChangeSuccess'
// import { isValidPassword } from '../../constants/utils'
import { toast } from 'sonner'
import { Form, Spin } from 'antd'
import AuthServices from '../../services/AuthServices'
const ModalConfirmChangePass = ({
  isOpen,
  onCancel,
  onConfirm,
  oldPassword,
  newPassword,
}) => {
  const [isPasswordChangeConfirmed, setIsPasswordChangeConfirmed] =
    useState(false)
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const handleSubmit = () => {
    form
      .validateFields()
      .then(async values => {
        try {
          const bodyData = {
            oldPassword: oldPassword,
            password: newPassword,
          }
          setLoading(true)
          const res = await AuthServices.updatePassword(null, bodyData)

          if (res.statusCode === 200 || res.statusCode === 201) {
            setIsPasswordChangeConfirmed(true)
          }
        } catch (error) {
          toast.error(error.response.data.message)
        } finally {
          setLoading(false)
        }
      })
      .catch(error => {
        toast.error(error.message)
      })
  }

  return (
    <ModalCommon
      title="パスワード変更"
      open={isOpen}
      width="740px"
      onOk={onConfirm}
      onCancel={onCancel}
      footer={
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '16px',
          }}
        >
          <Button onClick={onCancel}>キャンセル</Button>
          <Button onClick={handleSubmit} bg_color="#5BD4C1" color="white">
            決定
          </Button>
        </div>
      }
    >
      <Spin spinning={loading}>
        <div
          style={{
            color: '#000',
            margin: '142px',
            display: 'flex',
            fontSize: '15px',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          パスワードを変更します。
          <br />
          よろしいですか？
        </div>
      </Spin>
      {isPasswordChangeConfirmed && (
        <ModalChangeSuccess
          isOpen={isPasswordChangeConfirmed}
          onCancel={() => {
            setIsPasswordChangeConfirmed(false)
            onConfirm()
          }}
          onOk={() => {
            setIsPasswordChangeConfirmed(false)
            onConfirm()
          }}
        />
      )}
    </ModalCommon>
  )
}

export default ModalConfirmChangePass
