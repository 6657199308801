import { Button, Form, Input } from 'antd'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ACCESS_TOKEN_KEY } from '../../constants/init.constants'
import { ROUTE_PATH } from '../../routes/route.constant'
import AuthServices from '../../services/AuthServices'
import { isValidEmail, isValidPassword } from '../../constants/utils'
import { toast } from 'sonner'

const LoginForm = ({ onToggle }) => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const handleSubmit = () => {
    form
      .validateFields()
      .then(val => {
        setLoading(true)
        const body = {
          email: val.email,
          password: val.password,
          role: 'Admin',
        }
        AuthServices.login(body)
          .then(respon => {
            if (respon.data) {
              toast.success('ログインに成功しました')
              localStorage.setItem(
                ACCESS_TOKEN_KEY,
                JSON.stringify(respon.data),
              )
              navigate(ROUTE_PATH.MEMBER_MANAGEMENT)
              // onToggle()
            }
          })

          .finally(() => setLoading(false))
      })
      .catch(er => {})
  }

  return (
    <>
      <div className="text-welcome" style={{ paddingBottom: '60px' }}>
        おかえりなさい
      </div>
      <div>
        <Form form={form}>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'メールアドレスを入力してください',
              },
              {
                pattern: isValidEmail(),
                message: '有効なメールアドレスを入力してください',
              },
            ]}
            name={'email'}
          >
            <Input placeholder="メールアドレス" />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'パスワードを入力してください',
              },
              {
                pattern: isValidPassword(),
                message:
                  'パスワードは少なくとも 6 文字以上にする必要があります。',
              },
            ]}
            name={'password'}
          >
            <Input.Password placeholder="パスワード " />
          </Form.Item>
        </Form>
        <div className="btn-forgot" onClick={onToggle}>
          パスワードを忘れた場合
        </div>
        <Button loading={loading} className="btn-login" onClick={handleSubmit}>
          ログイン
        </Button>
      </div>
    </>
  )
}

export default LoginForm
